.UpdateUser-search-div-main {
    padding-bottom: 200px;
}

.UpdateUser-search-div {
    display: flex;
    align-items: start;
    gap: 20px;
    justify-content: center;
    /* background-color: rgb(50, 171, 205); */
}

.UpdateUser-search-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 600px;
    background-color: var(--color-primary);
    border: 2px solid rgb(141, 141, 141);
    border-radius: 4px;
}

.UpdateUser-search-input {
    width: 100%;
    background-color: transparent;
    height: 35px;

    &:focus {
        outline: none;
    }
}

.UpdateUser-search-img-show {
    width: 20px;
    height: 20px;
    transition: .5s;
    margin: 10px;

}

.UpdateUser-search-img-hide {
    width: 20px;
    height: 20px;
    rotate: 180deg;
    transition: .5s;
    margin: 10px;

}

.UpdateUser-search-result-div {
    display: flex;
    flex-direction: column;
    gap: 1px;
    background-color: var(--color-primary);
    height: 200px;
    width: 600px;
    z-index: 1;
    position: absolute;
    overflow-y: scroll;

}

.UpdateUser-search-result-div-hidden {
    display: none;
}

.UpdateUser-search-result-btn {
    width: 100%;
    border: none;

    &:hover {
        background-color: var(--color-secondary);
    }
}






.UpdateUser-info-div {
    margin: 20px auto;
}

.UpdateUser-info-Container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px auto;
}

.UpdateUser-info {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 600px;
    justify-content: space-between;
}





.UpdateUser-btn-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 10px;
}