@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.header-1 {
  /* font-family: 'poppins'; */
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: '#000000';

}

.input-1 {
  width: 288px;
  height: 53px;
  border-radius: 46px;
  border: 1px solid #00000036;
  opacity: 84%;
  background-color: #FEFFFF;
  padding: 0px 15px;

}

.input-2 {
  width: 288px;
  height: 53px;
  border-radius: 46px;
  border: 1px;
  opacity: 84%;
  background-color: #FEFFFF;
  padding: 0px 5px;
}

.label-1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;

}

.p1 {
  /* font-family: 'poppins'; */
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
}

.p2 {
  /* font-family: 'poppins'; */
  font-weight: 700;
  font-size: 19px;
  line-height: 28.5px;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}