.AddStores-info-div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.AddStores-form {
    display: flex;
    align-items: center;
    width: 600px;
    justify-content: space-between;
}

.AddStores-btn-div {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 10px;
    margin-top: 20px;
}