.app {
  /* display: flex; */
}

.SideBar {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 3;
}

.App-content {
  margin: 0px 0px 0px 90px;
  /* background-color: rgb(11, 125, 41); */

}




body {
  background-color: rgb(247, 248, 249);
}

:root {
  --color-primary: rgb(225, 240, 218);
  /* Main green color */

  --color-secondary: rgb(191, 216, 175);
  /* Main green color */


  --color-text: #333333;
  /* Dark text color */

  --color-link: #2196F3;
  /* Blue link color */
  --blue: #065ba0;

  --warning-color: rgb(225, 132, 132);


  /* colors */

  --background: #EDF7F6;

  --blue_01: #164863;
  --blue_02: #427D9D;
  --blue_03: #9BBEC8;
  --blue_04: #DDF2FD;

  --green_01: #99BC85;
  --green_02: #BFD8AF;
  --green_03: #D4E7C5;
  --green_04: #E1F0DA;

  --black: #1d1d1f;
  --white: #ffffff;
  --warning: #db162f;
  --succesful: #1ec91c;

  --inactiveColor: #5B6D49;

}

