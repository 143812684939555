/** @format */

.UpdateItem-search-div {
  margin-bottom: 200px;
}

.UpdateItem-search {
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 20px;
}

.no-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  background-color: #ffffff; /* Ensures the select background is white, or any other color */
  padding-right: 15px; /* Adjust to create space at the right */
}

.UpdateItem-search-btn {
  width: 600px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--color-primary);
  border: 2px solid rgb(141, 141, 141);
  border-radius: 4px;
  padding-left: 5px;
}

.UpdateItem-search-input {
  width: 100%;
  height: 35px;
  border: none;
  background-color: transparent;
}

.UpdateItem-search-input:focus {
  outline: 0px solid #ddd;
}

.UpdateItem-search-img-show {
  width: 15px;
  height: 15px;
  transition: 0.5s;
  margin: 10px;
}

.UpdateItem-search-img-hide {
  width: 15px;
  height: 15px;
  rotate: 180deg;
  transition: 0.5s;
  margin: 10px;
}

.UpdateItem-search-result-div-show {
  background-color: var(--color-primary);
  width: 600px;
  position: absolute;
  z-index: 1;
  height: 200px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.UpdateItem-search-result-div-hide {
  display: none;
}

.UpdateItem-search-result-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: none;
}

.UpdateItem-search-result-btn:hover {
  background-color: var(--color-secondary);
}

/* Info Div Styles */
.UpdateItem-info-div {
}

.UpdateItem-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px auto;
}

.UpdateItem-info-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 600px;
  margin: 10px 0px;
}
.form-input {
  width: 290px;
  height: 100px;
  border-radius: 15px;
  margin: 10px 0px;
}
