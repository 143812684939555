.StoreKeeperRelease {
    margin: auto 10px;
}

.StoreKeeperRelease-info-main-div {
    /* background-color: violet; */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.StoreKeeperRelease-info-input-div {
    /* border: 1px solid black; */
    width: 600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
}

.StoreKeeperRelease-info-input-label {}

.StoreKeeperRelease-info-input {}





.StoreKeeperRelease-search-main-div {
    width: 100%;
    margin-bottom: 200px;
}

.StoreKeeperRelease-search-select-div {
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 20px;
}

.StoreKeeperRelease-search-div-select {
    display: flex;
    flex-direction: column;

}

.StoreKeeperRelease-search-div-select-btn {
    width: 600px;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: var(--color-primary);
    border: 2px solid rgb(141, 141, 141);
    border-radius: 4px;
}

.StoreKeeperRelease-search-div-select-input {
    width: 100%;
    height: 30px;
    border: none;
    background-color: transparent;

    &:focus {
        outline: 0px;
    }
}

.StoreKeeperRelease-search-results-div {
    width: 600px;
    position: absolute;
    margin-top: 40px;
    background-color: var(--color-primary);
    height: 180px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 1px;

}

.StoreKeeperRelease-search-results-btn {
    width: 100%;
    background-color: rgb(246, 243, 243);
    border: none;

    &:hover {
        background-color: var(--color-secondary);
    }
}

.StoreKeeperRelease-table-div {}

.StoreKeeperRelease-t-1 {
    width: 5%;
}

.StoreKeeperRelease-t-2 {
    width: 35%;
}

.StoreKeeperRelease-t-3 {
    width: 15%;
}

.StoreKeeperRelease-t-4 {
    width: 10%;
}

.StoreKeeperRelease-t-5 {
    width: 10%;
}

.StoreKeeperRelease-t-6 {
    width: 5%;
}

.StoreKeeperRelease-t-7 {
    width: 20%;
}

.StoreKeeperRelease-table-input {
    border: 2px solid rgb(141, 141, 141);
    border-radius: 4px;
}





.StoreKeeperRelease-btn-main-div {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 10px;
    margin-top: 20px;
}











.StoreKeeperRelease-input-main-div {
    margin-top: 50px;
    /* background-color: yellow; */
}