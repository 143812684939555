.AddUser-form-Container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.AddUser-form-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 550px;
}

.AddUser-btn-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 20px;
    margin: 30px auto 0px auto;
}