/* Increase the size of the calendar */
.react-datepicker {
    font-size: 16px; /* Adjust font size for readability */
  }
  
  .react-datepicker__header {
    font-size: 18px; /* Increase the header font size */
  }
  
  .react-datepicker__day, .react-datepicker__day-name {
    width: 3.5rem; /* Increase width of days */
    height: 3.5rem; /* Increase height of days */
  }
  
  .react-datepicker__month {
    width: 20rem; /* Increase width of the calendar */
  }
  
  .react-datepicker__day--highlighted {
    background-color: red; /* Customize highlight color */
    color: white; /* Customize highlight text color */
  }
  
  .react-datepicker__current-month {
    font-size: 20px; /* Increase current month font size */
  }
  