.UpdateSupplier-search-div-main {
    padding-bottom: 200px;
}

.UpdateSupplier-search-div {
    display: flex;
    align-items: start;
    gap: 20px;
    justify-content: center;
}

.UpdateSupplier-search-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 600px;
}

.UpdateSupplier-search-input {
    width: 100%;
}

.UpdateSupplier-search-image-show {
    width: 15px;
    height: 15px;
    transition: .5s;
    margin: 10px
}

.UpdateSupplier-search-img-hide {
    width: 15px;
    height: 15px;
    rotate: 180deg;
    transition: .5s;
    margin: 10px;
}

.UpdateSupplier-search-result-div {
    display: flex;
    flex-direction: column;
    background-color: rgb(217, 0, 255);
    height: 200px;
    width: 600px;
    z-index: 1;
    position: absolute;
}

.UpdateSupplier-search-result-div-hidden {
    display: none;
}

.UpdateSupplier-info-div {}

.UpdateSupplier-info-Container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.UpdateSupplier-info {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 600px;
    justify-content: space-between;
}


.UpdateSupplier-btn-Container {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20px;
    margin: 20px 0px 10px 0px;
}

.UpdateSupplier-add-btn {
    background-color: var(--color-secondary);
}

.UpdateSupplier-cancel-btn {
    background-color: var(--warning-color);
}